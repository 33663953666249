@import url("https://use.typekit.net/jam1efk.css");

body {
  background-color: #eeeeee;
}

.my-masonry-grid {
    display: -webkit-box; /* Not needed if autoprefixing */
    display: -ms-flexbox; /* Not needed if autoprefixing */
    display: flex;
    margin-left: -30px; /* gutter size offset */
    width: auto;
    padding: 40px;
    overflow-y: auto;
  }
  .my-masonry-grid_column {
    padding-left: 30px; /* gutter size */
    background-clip: padding-box;
  }
  
  /* Style your items */
  .my-masonry-grid_column > div { /* change div to reference your elements you put in <Masonry> */
    background: grey;
    margin-bottom: 30px;
  }

  .scroll-container {
    width: 100%;
    height: 100%;
    overflow-y: auto;
    padding: 20px 0px;
    position: absolute;
    top: 0px;
    left: 0px;
    z-index: 1;
    padding-top: 90px;
    overflow-x: hidden;
  }

  .page-container {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
  }

  .page-header {
    background-color: white;
    display: flex;
    flex-direction: row;
    align-items: center;
    position: absolute;
    top: 0px;
    left: 0px;
    z-index: 200;
    width: 100%;
    height: 80px;
    padding: 7px 25px;
  }

  .logo {
    flex: 1;
  }

  .logo img {
    height: 60px;
  }

  .swatch {
    text-align: right;
    margin-left: 15px;
  }

  .current-color {
    background-color: #eeeeee;
    box-shadow: 0px 0px 10px rgba(0,0,0,0.2);
    width: 50px;
    height: 50px;
    border-radius: 50px;
  }

  .help {
    font-size: .9em;
    color: rgba(0,0,0,0.7);
    text-align: center;
  }

  .shadow-card {
    /* box-shadow: 0px 0px 20px rgba(0,0,0,0.01); */
  }